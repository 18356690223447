import { Link } from "react-router-dom";
import * as Vars from "../js/vars";

function Header({ ...props }) {
    
    //MENUS
    const menus = {
        home: "",
        thegame: "",
        universe: "",
    };

    //SUBMENUS
    let i = 0;
    const submenus = {};
    
    for (const key in menus) {
      submenus[`submenu${i}`] = "";
      i++;
    }

    //LOGOS
    const logos = {
        logo1: "",
        logo2: "",
    }

    return (
        <header>
            <nav>
                <div className="menus logos logo1">{props.logo1}</div>
                <div className="menus logos logo2">{props.logo2}</div>
                <ul className="menus menu">
                    <li>{props.home}</li>
                    <span>|</span>
                    <li>
                        {props.thegame}
                        <div className="submenu">
                            <div className="layout">
                                <figure>
                                    {Vars.headerTheme1}
                                </figure>
                                <ul>
                                    <li>{props.submenu0}</li>
                                    <li>{props.submenu1}</li>
                                    <li>{props.submenu2}</li>
                                    <li>{props.submenu3}</li>
                                    <li>{props.submenu4}</li>
                                    <li>{props.submenu5}</li>
                                    <li>{props.submenu6}</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <span>|</span>
                    <li>
                        {props.universe}
                        <div className="submenu">
                            <div className="layout">
                                <figure>
                                    {Vars.headerTheme2}
                                </figure>
                                <ul>
                                    <li>{props.submenu10}</li>
                                    <li>{props.submenu11}</li>
                                    <li>{props.submenu12}</li>
                                </ul>
                            </div>
                        </div>
                    </li>
                    <span>|</span>
                    <li><Link to="/">EN</Link></li>
                    <li><Link to="/languages/">PT</Link></li>
                </ul>
                <ul className="menus social">
                    <li>
                        <img
                            src="https://repository.herolix.com/images/icon_discord.svg"
                            width="32px"
                            height="32px"
                            alt="Discord Icon Link"
                        />
                    </li>
                    <li>
                        <a href="#">
                            <img
                                src="https://repository.herolix.com/images/icon_facebook.svg"
                                width="25px"
                                height="25px"
                                alt="Facebook Icon Link"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                                src="https://repository.herolix.com/images/icon_instagram.svg"
                                width="25px"
                                height="25px"
                                alt="Instagram Icon Link"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                                src="https://repository.herolix.com/images/icon_x.svg"
                                width="25px"
                                height="25px"
                                alt="Twitter Icon Link"
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <img
                                src="https://repository.herolix.com/images/icon_youtube.svg"
                                width="34px"
                                height="34px"
                                alt="Youtube Icon Link"
                            />
                        </a>
                    </li>
                </ul>
                <ul className="menus mobile">
                    <li>
                        <a href="#">HOME</a>
                    </li>
                    <li>
                        <a href="#">THE GAME</a>
                    </li>
                    <li>
                        <a href="#">UNIVERSE</a>
                    </li>
                    <li>
                        <a href="#">KUBBERPEDIA</a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
