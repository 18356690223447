//PT

import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import * as Functions from "../js/functions";
import * as Translates from "../js/translates";

import Header from "../contents/Header";

function Home() {
    
    useEffect(() => {
        const hash = window.location.hash;
        let element;

        switch (hash) {
            case "#home":
                element = document.getElementById("home");
                break;

            case "#thegame":
                element = document.getElementById("thegame");
                break;

            case "#universe":
                element = document.getElementById("universe");
                break;

            default:
                break;
        }

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <>
            <Header
                //MAIN MENUS
                home={<a href="#home">HOME</a>}
                thegame={<a href="#thegame">THE GAME</a>}
                universe={<a href="#universe">UNIVERSE</a>}
                //SUB MENUS
                submenu0={<Link to="/thegame/#koinz">EARN KOINZ</Link>}
                submenu1={<Link to="/thegame/#koinz">SEARCHING KUBBERZ</Link>}
            />
            <main id="mainHome">
                <section id="home" className="section1">
                    <div className="container">
                        <p>{Translates.welcome}</p>
                    </div>
                </section>

                <section id="thegame" className="section2">
                    <div className="container">
                        <p>thegame</p>
                    </div>
                </section>

                <section id="universe" className="section3">
                    <div className="container">
                        <p>universe</p>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Home;
