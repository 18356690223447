// HEADER 
// LOGOS TRASNICTIONS
export let logoTransitions = (
    window.addEventListener("scroll", function () {
        let scroll = window.pageYOffset;

        let header = document.querySelector("header");
        let logo1 = document.querySelector(".logo1");
        let logo2 = document.querySelector(".logo2");
        switch (true) {
            case scroll >= 500:
                header.classList.add("scrolling");
                logo1.style.display = 'none';
                logo2.style.display = 'inherit';
                break;
            default:
                header.classList.remove("scrolling");
                logo1.style.display = 'inherit';
                logo2.style.display = 'none';
                break;
        }
    })
)

//PARALLAX
window.addEventListener("scroll", function (event) {
    var topDistance = this.pageYOffset;
    var layers = document.querySelectorAll("[data-type='parallax']");
    for (var i = 0; i < layers.length; i++) {
        var layer = layers[i];
        var depth = layer.getAttribute("data-depth");
        var translate3d =
            "translate3d(0, " + -(topDistance * depth) + "px, 0)";
        layer.style["-webkit-transform"] = translate3d;
        layer.style["-moz-transform"] = translate3d;
        layer.style["-ms-transform"] = translate3d;
        layer.style["-o-transform"] = translate3d;
        layer.style.transform = translate3d;
    }
});

export const centerClick2 = () => {
    let clickCenter = document.querySelector('.section2');
    clickCenter.scrollIntoView();
};

export const centerClick3 = () => {
    let clickCenter = document.querySelector('.section3');
    clickCenter.scrollIntoView();
};

export const centerClick4 = () => {
    let clickCenter = document.querySelector('.section4');
    clickCenter.scrollIntoView();
};

export const centerClick5 = () => {
    let clickCenter = document.querySelector('.section5');
    clickCenter.scrollIntoView();
}


//LOGIN TESTE
export const send = async (event) => {
    event.preventDefault();

    const apiUrl = "https://209.145.53.145:3002/users/login";
    const nickname = document.getElementById("nickname").value;
    const password = document.getElementById("password").value;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                mode: "no-cors",
            },
            body: JSON.stringify({ nickname, password }),
        });

        console.log("Código de Status:", response.status);

        const data = await response.json();
        console.log("Resposta da API:", data);

    } catch (error) {
        console.error("Erro ao fazer a solicitação:", error);
    }
};
