// HEADER
// LOGOS IMAGES
export let logoHerolix = (
    <img
        src="https://repository.herolix.com/images/logo_herolix_white.webp"
        alt="Herolix Logo"
        width="132px"
        height="40px"
    />
);

export let logoKubberzBlue = (
    <img
        src="https://repository.herolix.com/images/logo_kubberz_blue.webp"
        alt="Logo Kubberz"
        width="165px"
        height="40px"
    />
);

// HEADER SUBMENU THEMES
export let headerTheme1 = (
    <img
        src="https://repository.herolix.com/images/header_nav_01.webp"
        alt="Logo Kubberz"
        width="350px"
        height="300px"
    />
);

export let headerTheme2 = (
    <img
        src="https://repository.herolix.com/images/header_nav_02.webp"
        alt="Logo Kubberz"
        width="350px"
        height="300px"
    />
);
















