import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import * as Vars from "../js/vars";

import Header from "../contents/Header";

function TheGame() {
    useEffect(() => {
        const hash = window.location.hash;
        let element;

        switch (hash) {
            case "#koinz":
                element = document.getElementById("koinz");
                break;

            case "#manopla":
                element = document.getElementById("manopla");
                break;

            default:
                break;
        }

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <>
            <Header
                //LOGOS
                logo1={Vars.logoHerolix}
                logo2={Vars.logoKubberzBlue}
                //MAIN MENUS
                home={<Link to="/#home">HOME</Link>}
                thegame={<a href="#thegame">THE GAME</a>}
                universe={<a href="#universe">UNIVERSE</a>}
                //SUB MENUS THE GAME
                submenu0={<Link to="/thegame/#koinz">EARN KOINZ</Link>}
                submenu1={<Link to="/thegame/#koinz">SEARCHING KUBBERZ</Link>}
                submenu2={<Link to="/thegame/#koinz">MANOPLA Z</Link>}
                submenu3={<Link to="/thegame/#koinz">SKILL POINTS</Link>}
                submenu4={<Link to="/thegame/#koinz">BATTLES</Link>}
                submenu5={<Link to="/thegame/#koinz">OPPONENTS</Link>}
                submenu6={<Link to="/thegame/#koinz">EXPERIENCE</Link>}
                //SUB MENUS UNIVERSE
                submenu10={<Link to="/thegame/#koinz">LINEAGES</Link>}
                submenu11={<Link to="/thegame/#koinz">KUBBER TYPES</Link>}
                submenu12={<Link to="/thegame/#koinz">COLOSSUS</Link>}
            />
            <main>
                <section id="thegame" className="section1">
                    <div className="container">
                        <p>TheGame</p>
                    </div>
                </section>

                <section id="koinz" className="koinz">
                    <div className="container">
                        <p>koinz 1!</p>
                    </div>
                </section>

                <section id="manopla" className="manopla">
                    <div className="container">
                        <p>manopla!</p>
                    </div>
                </section>
            </main>
        </>
    );
}

export default TheGame;
