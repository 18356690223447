import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import * as Vars from "../js/vars";

import Header from "../contents/Header";

function Universe() {
    useEffect(() => {
        const hash = window.location.hash;
        let element;

        switch (hash) {
            case "#lineages":
                element = document.getElementById("lineages");
                break;

            case "#other":
                element = document.getElementById("other");
                break;

            default:
                break;
        }

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <>
            <Header
                //LOGOS
                logo1={Vars.logoHerolix}
                logo2={Vars.logoKubberzBlue}
                //MAIN MENUS
                home={<Link to="/">HOME</Link>}
                thegame={<a href="#thegame">THE GAME</a>}
                universe={<a href="#universe">UNIVERSE</a>}
                //SUB MENUS
                submenu0={<Link to="/thegame/#koinz">koinz</Link>}
            />
            <main>
                <section id="universe" className="section1">
                    <div className="container">
                        <p>UNIVERSE</p>
                    </div>
                </section>

                <section id="lineages">
                    <div className="container">
                        <p>LINEAGES!</p>
                    </div>
                </section>

                <section id="other">
                    <div className="container">
                        <p>OTHER!</p>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Universe;
