import React from 'react';

const Modal_Evolve = () => {

    function openModal() {
        let modal = document.getElementById('modalEvolve');
        document.body.style.overflow = 'hidden';
        modal.showModal();
    };

    function closeModal() {
        let modal = document.getElementById('modalEvolve');
        document.body.style.overflow = 'auto';
        modal.close();
    };

    return (
        <>
            <button onClick={openModal}>+ EXPERIENCE</button>
            <dialog id="modalEvolve" onClick={closeModal}>
                <div className="layout">
                    <div className="theme evolve">
                        <div className="schema theme-image">
                            <img src="https://kubberz.com/assets/images/char_493_raptorama.webp" alt="popup theme image" width="493px" height="400px" />
                        </div>
                        <div className="schema texts">
                            <p className="medium">Evolve</p>
                            <p>
                                Evolution is the essence of a kubberz's journey. With training and experience, they transcend their forms, reaching new levels of power. Every battle, every adventure, is an opportunity for your kubberz to approach evolution, unlocking abilities that will reverberate across the battlefields.
                                <br /><br />
                                Everyone will find challenges that will resonate throughout their lives. So, gather your courage, prepare for the unknown, and uncover a universe of unexplored opportunities.
                                As it echoes through the streets of Pelegrino: Adventure calls to those willing to answer.
                            </p>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Modal_Evolve;
