//EN
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as Vars from "../js/vars";
import * as Functions from "../js/functions";

import Header from "../contents/Header";

import Modal_Explore from "../contents/Modal_Explore";
import Modal_Capture from "../contents/Modal_Capture";
import Modal_Evolve from "../contents/Modal_Evolve";
import Modal_Figth from "../contents/Modal_Figth";
import Modal_Succeed from "../contents/Modal_Succeed";

function Home() {

    useEffect(() => {
        const hash = window.location.hash;
        let element;

        switch (hash) {
            case "#home":
                element = document.getElementById("home");
                break;

            case "#thegame":
                element = document.getElementById("thegame");
                break;

            case "#universe":
                element = document.getElementById("universe");
                break;

            default:
                break;
        }

        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    function teaserPlay() {
        const video = document.getElementById("teaservideo");
        video.play();
        teaserMute();
    }

    const centerClick2 = () => {
        let clickCenter = document.querySelector('.section2');
        clickCenter.scrollIntoView();
        teaserPlay()
    };

    function teaserMute() {
        const video = document.getElementById("teaservideo");
        const section = document.querySelector(".section2");
        document.querySelector(".start-teaser-color").classList.add("teaser-color");
        let muteText = document.querySelector(".mute");

        section.addEventListener("click", function () {
            video.muted = !video.muted;
            muteText.textContent = video.muted ? "TOUCH TO ACTIVE SOUND" : "TOUCH TO DEACTIVATE SOUND";
        })

        section.addEventListener("mouseleave", function () {
            video.muted = true;
            muteText.textContent = "SOUND OFF | TOUCH TO ACTIVE SOUND";
        });

        section.addEventListener("mouseenter", function () {
            video.muted = false;
            muteText.textContent = "SOUND ON | TOUCH TO DEACTIVATE SOUND";
        });
    }

    function handleOrientationChange() {
        const video = document.getElementById("teaservideo");
        if (window.innerWidth < window.innerHeight) {
            if (video.requestFullscreen) {
                video.requestFullscreen();
            } else if (video.mozRequestFullScreen) {
                // Firefox
                video.mozRequestFullScreen();
            } else if (video.webkitRequestFullscreen) {
                // Chrome, Safari e Opera
                video.webkitRequestFullscreen();
            } else if (video.msRequestFullscreen) {
                // IE/Edge
                video.msRequestFullscreen();
            }
        }
    }

    window.addEventListener("orientationchange", handleOrientationChange, { passive: true });

    return (
        <>
            <Header
                //LOGOS
                logo1={Vars.logoHerolix}
                logo2={Vars.logoKubberzBlue}
                //MAIN MENUS
                home={<a href="#home">HOME</a>}
                thegame={<a href="#thegame">THE GAME</a>}
                universe={<a href="#universe">UNIVERSE</a>}
                //SUB MENUS THE GAME
                submenu0={<Link to="/thegame/#koinz">EARN KOINZ</Link>}
                submenu1={<Link to="/thegame/#koinz">SEARCHING KUBBERZ</Link>}
                submenu2={<Link to="/thegame/#koinz">MANOPLA Z</Link>}
                submenu3={<Link to="/thegame/#koinz">SKILL POINTS</Link>}
                submenu4={<Link to="/thegame/#koinz">BATTLES</Link>}
                submenu5={<Link to="/thegame/#koinz">OPPONENTS</Link>}
                submenu6={<Link to="/thegame/#koinz">EXPERIENCE</Link>}
                //SUB MENUS UNIVERSE
                submenu10={<Link to="/thegame/#koinz">LINEAGES</Link>}
                submenu11={<Link to="/thegame/#koinz">KUBBER TYPES</Link>}
                submenu12={<Link to="/thegame/#koinz">COLOSSUS</Link>}
            />

            <main id="mainHome">
                <section id="home" className="section1">
                    <div className="container">
                        <div className="sun">
                            <video reload="metadata" autoPlay loop muted>
                                <source src="https://repository.herolix.com/videos/sun.mp4" type="video/webm" preload="metadata" />
                            </video>
                        </div>
                        <div className="layers slight" data-type="parallax" data-depth="-1"></div>
                        <div className="layers layer7" data-type="parallax" data-depth="-1.0"></div>
                        <div className="layers layer6" data-type="parallax" data-depth="-0.98"></div>
                        <div className="layers layer5" data-type="parallax" data-depth="-0.8"></div>
                        <div className="layers logos" data-type="parallax" data-depth="-0.35">
                            <div>
                                <a href="https://kubberz.com/">
                                    <img className="logosimg logo1" src="https://repository.herolix.com/images/logo_kubberz_blue_500.webp" width="500px" height="119px" alt="Logo Kubberz" />
                                </a>
                                <a href="https://store.steampowered.com/app/1561000/Kubberz/" target="_blank">
                                    <img className="logosimg logo2" src="https://repository.herolix.com/images/freetoplay.webp" width="450px" height="75px" alt="Banner Free to Play" />
                                </a>
                            </div>
                        </div>
                        <div className="layers layer4" data-type="parallax" data-depth="-0.75"></div>
                        <div className="layers layer3" data-type="parallax" data-depth="-0.65"></div>
                        <div className="layers layer2" data-type="parallax" data-depth="-0.50"></div>
                        <div className="layers layer1" data-type="parallax" data-depth="-0.00"></div>
                    </div>
                    <div className="bottom">
                        <a href="#teaser" onClick={teaserPlay}>
                            <img src="https://repository.herolix.com/images/animation_01.gif" alt="Logo Kubberz" width="142px" height="108px" />
                            <button>
                                <p className="a4">TELL ME MORE!</p>
                            </button>
                        </a>
                    </div>
                </section>

                <section id="teaser" className="section2" onClick={centerClick2}>
                    <div className="start-teaser-color"></div>
                    <div className="container">
                        <div className="video-layout">
                            <video id="teaservideo" loop >
                                <source src="https://repository.herolix.com/videos/kubberz_alphateaser_720p.mp4" type="video/webm" preload="metadata" />
                            </video>
                            <div className="mute-layout">
                                <p className="mute">TAP TO PLAY!</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="play" className="section3" onClick={Functions.centerClick3}>

                    <div className="container">
                        <div className="card">
                            <p className="title medium">explore</p>
                            <video preload="metadata" autoPlay loop muted>
                                <source src="https://repository.herolix.com/videos/animation_card_explore.webm" type="video/webm" />
                                Your browser does not support MP4 Format videos or HTML5 Video.
                            </video>
                            <p className="p1">
                                Uncover the secrets of Sphera, a new world filled with incredible challenges, to experience an unforgettable adventure.
                            </p>
                            <div className="link">
                                <Modal_Explore />
                            </div>
                        </div>

                        <div className="card-layout">
                            <div className="card">
                                <p className="title medium">capture</p>
                                <video preload="metadata" autoPlay loop muted>
                                    <source src="https://repository.herolix.com/videos/animation_card_capture.webm" type="video/webm" />
                                    Your browser does not support MP4 Format videos or HTML5 Video.
                                </video>
                                <p className="p1">
                                    Use the Z Cube to capture kubberz and learn more about each of them; some of them are waiting to be discovered.
                                </p>
                                <div className="link">
                                    <Modal_Capture />
                                </div>
                            </div>
                            <div className="card">
                                <p className="title medium">evolve</p>
                                <video preload="metadata" autoPlay loop muted>
                                    <source src="https://repository.herolix.com/videos/animation_card_evolve.webm" type="video/webm" />
                                    Your browser does not support MP4 Format videos or HTML5 Video.
                                </video>
                                <p className="p1">
                                    Gain experience to evolve each of your kubberz, whether through intense battles or thrilling adventures.
                                </p>
                                <div className="link">
                                    <Modal_Evolve />
                                </div>
                            </div>
                        </div>
                        <div className="card-layout">
                            <div className="card">
                                <p className="title medium">fight</p>
                                <video preload="metadata" autoPlay loop muted>
                                    <source src="https://repository.herolix.com/videos/animation_card_fight.webm" type="video/webm" />
                                    Your browser does not support MP4 Format videos or HTML5 Video.
                                </video>
                                <p className="p1">
                                    Challenge your potential by battling wild kubberz or other trainers scattered throughout Sphera.
                                </p>
                                <div className="link">
                                    <Modal_Figth />
                                </div>
                            </div>
                            <div className="card">
                                <p className="title medium">succeed</p>
                                <video preload="metadata" autoPlay loop muted>
                                    <source src="https://repository.herolix.com/videos/animation_card_succeed.webm" type="video/webm" />
                                    Your browser does not support MP4 Format videos or HTML5 Video.
                                </video>
                                <p className="p1">
                                    Immortalize your name and kubberz in the Citadel of Champions, the most coveted hall of fame for trainers throughout Sphera.
                                </p>
                                <div className="link">
                                    <Modal_Succeed />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="thegame" className="section4" onClick={Functions.centerClick4}>
                    <div className="container">
                        <p>thegame</p>
                    </div>
                </section>

                <section id="universe" className="section5" onClick={Functions.centerClick5}>
                    <div className="container">
                        <p>universe</p>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Home;
