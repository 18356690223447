import React from 'react';

const Modal_Figth = () => {

    function openModal() {
        let modal = document.getElementById('modalFight');
        document.body.style.overflow = 'hidden';
        modal.showModal();
    };

    function closeModal() {
        let modal = document.getElementById('modalFight');
        document.body.style.overflow = 'auto';
        modal.close();
    };

    return (
        <>
            <button onClick={openModal}>Secrets of Sphera</button>
            <dialog id="modalFight" onClick={closeModal}>
                <div className="layout">
                    <div className="theme fight">
                        <div className="schema theme-image">
                            <img src="https://kubberz.com/assets/images/char_493_bigbeef.webp" alt="popup theme image" width="493px" height="400px" />
                        </div>
                        <div className="schema texts">
                            <p className="medium">Fight</p>
                            <p>
                                In Sphera, every battle is a chance to prove your worth. Wild kubberz, with their unexpected natures, and rival trainers, with their refined strategies, provide confrontations that will test your skill and determination. Even in the most peaceful cities of Floramaris, the competitive spirit simmers, with combat challenges awaiting around every corner.
                                <br /><br />
                                Each encounter is a lesson, each defeat will fuel your next battle, and each victory is one more step towards mastery. In Sphera, battle is not just about winning; it's about growing, learning, and evolving alongside your kubberz.
                            </p>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Modal_Figth;
