import React from 'react';

const Modal_Capture = () => {

    function openModal() {
        let modal = document.getElementById('modalCapture');
        document.body.style.overflow = 'hidden';
        modal.showModal();
    };
    
    function closeModal() {
        let modal = document.getElementById('modalCapture');
        document.body.style.overflow = 'auto';
        modal.close();
    };    

    return (
        <>
            <button onClick={openModal}>Z CUBE</button>
            <dialog id="modalCapture" onClick={closeModal}>
                <div className="layout">
                    <div className="theme capture">
                        <div className="schema theme-image">
                            <img src="https://kubberz.com/assets/images/char_493_capvolts.webp" alt="popup theme image" width="493px" height="400px" />
                        </div>
                        <div className="schema texts">
                            <p className="medium">Z Cube</p>
                            <p>
                                The Z Cube is not just a device; it's the key to a world of discoveries. Created to ease the journey of adventurers and aid the work of scientists, each Z Cube is a sanctuary for captured kubberz, where they float and rest in complete serenity, awaiting the moment to join forces with their trainer.
                                <br /><br />
                                Whether to reach the highest rank in kubberz battles or simply to collect the rarest creatures, the Z Cube has become an indispensable tool on the journey of any trainer willing to unravel the mysteries of every kubberz that crosses their path.
                            </p>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Modal_Capture;
