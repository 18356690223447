import React from 'react';

const Modal_Succeed = () => {

    function openModal() {
        let modal = document.getElementById('modalSucceed');
        document.body.style.overflow = 'hidden';
        modal.showModal();
    };

    function closeModal() {
        let modal = document.getElementById('modalSucceed');
        document.body.style.overflow = 'auto';
        modal.close();
    };

    return (
        <>
            <button onClick={openModal}>Secrets of Sphera</button>
            <dialog id="modalSucceed" onClick={closeModal}>
                <div className="layout">
                    <div className="theme succeed">
                        <div className="schema theme-image">
                            <img src="https://kubberz.com/assets/images/char_493_evohoo.webp" alt="popup theme image" width="493px" height="400px" />
                        </div>
                        <div className="schema texts">
                            <p className="medium">Succeed</p>
                            <p>
                                The Citadel of Champions in Sphera is the pinnacle of glory where trainers and their kubberz aspire to be immortalized. This hall of fame, more than just an imposing structure, is a symbol of mastery and courage, where the legends of past champions inspire new trainers to face epic battles and unforgettable adventures.
                                <br /><br />
                                Those who prove their worth have their names etched into the ancient stones, leaving a legacy that shines through the ages, encouraging future generations to pursue greatness in their own heroic journeys.
                            </p>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Modal_Succeed;
