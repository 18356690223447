import React from "react";
import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Route, Link, Routes } from "react-router-dom";

import fonts from "./components/css/tipography.css";
import animations from "./components/css/animations.css";
import header from "./components/css/header.css";
import home from "./components/css/home.css";


import Header from "./components/contents/Header";
import Footer from "./components/contents/Footer";

//EN
import Home from "./components/pages/Home";
import TheGame from "./components/pages/TheGame";
import Universe from "./components/pages/Universe";

//PT
import Home_pt from "./components/languages/Home";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/thegame" element={<TheGame />} />
                <Route path="/universe" element={<Universe />} />
                <Route path="/languages/" element={<Home_pt />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    );
}

export default App;
