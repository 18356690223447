import * as Vars from "../js/vars";
import styles from "../css/footer.module.css";
function Footer() {
    return (
        <>
            <footer>
                <div className={styles.container}>
                    {Vars.logoHerolix}
                    <p>© 2022 Kubberz. All rights reserved.</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;
