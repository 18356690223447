import React from 'react';

const Modal_Explore = () => {

    function openModal() {
        let modal = document.getElementById('modalExplore');
        document.body.style.overflow = 'hidden';
        modal.showModal();
    };
    
    function closeModal() {
        let modal = document.getElementById('modalExplore');
        document.body.style.overflow = 'auto';
        modal.close();
    };

    return (
        <>
            <button onClick={openModal}>Secrets of Sphera</button>
            <dialog id="modalExplore" onClick={closeModal}>
                <div className="layout">
                    <div className="theme explore">
                        <div className="schema theme-image">
                            <img src="https://kubberz.com/assets/images/char_493_mambascar.webp" alt="popup theme image" width="493px" height="400px" />
                        </div>
                        <div className="schema texts">
                            <p className="medium">Explore</p>
                            <p>
                                The audacity to explore Sphera separates the brave from the cautious. Among futuristic cities and humble villages, Sphera's biomes hide challenges that will test the limits of any adventurer. 
                                Filled with natural traps and wild kubberz, any journey to an unknown place could be the last. And it doesn't matter if you're a novice or a veteran; the mysteries of Sphera don't discriminate. 
                                <br /><br />
                                Everyone will find challenges that will resonate throughout their lives. So, gather your courage, prepare for the unknown, and uncover a universe of unexplored opportunities. 
                                As it echoes through the streets of Pelegrino: Adventure calls to those willing to answer.
                            </p>
                        </div>
                    </div>
                </div>
            </dialog>
        </>
    );
};

export default Modal_Explore;
